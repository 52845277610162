import React, { Suspense } from 'react'
import './App.css';
import { AutoSwitchLayout } from './layout';
import RenderRoutes from './routes/RenderRoutes'
import { FluentProvider, webLightTheme, Text } from '@fluentui/react-components';
import { NotificationsProvider, setUpNotifications } from 'reapop'
import NotificationPopup from './components/Debounce/NotificationPopup';

setUpNotifications({
  defaultProps: {
    position: 'top-right',
    dismissible: true,
    dismissAfter: 3000,
    allowHTML: true,
  },
})

function App() {

  return (
    <FluentProvider theme={webLightTheme}>
      <NotificationsProvider>
        
        <AutoSwitchLayout />
        <Suspense fallback={<div>Page Loading...</div>}>
          <RenderRoutes />
        </Suspense>
        <NotificationPopup />
      </NotificationsProvider>
    </FluentProvider>
  );
}

export default App;
