import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css'

interface NavbarProps {

}

const Navbar: React.FC<NavbarProps> = () => {
    return (
        <div className='appHeader'>
            <div className='logo-flex'>
                <img src='/poorvikalogonew.png' className='logoSize' />
            </div>
            <ul className='navbar-list'>
                <Link to='/'><li>Home</li></Link>
                <Link to='/'><li>Delivery partner</li></Link>
                {/* <li>Delivery Partner</li> */}
                <Link to='/'><li>Live tracking</li></Link>
                <Link to='/'><li>Notifications</li></Link>
                <Link to='/'><li>History</li></Link>
                <Link to='/'><li>Login</li></Link>
            </ul>
        </div>
    );
};

export default Navbar;