import React from 'react'
import Navbar from './TopNavbar/Navbar'

export function WebLayout(){
    return(
        <div>
            <Navbar />
            {/* <div>
                <img src="/web-banner.webp"  />
            </div> */}
        </div>
    )
}