import React from 'react';
import {WebLayout} from './WebLayout';
import {BlankLayout} from './BlankLayout'

export const AutoSwitchLayout: React.FC<any> = ({children}) => {
    const isAuthenticated  = true
    const Layout = isAuthenticated ? WebLayout : BlankLayout

    return (
        <>
            <Layout>{children}</Layout>
        </>
    );
};

