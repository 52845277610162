import exp from 'constants';
import React from 'react'

const routeConfig = [
    {
        path : '/',
        element : React.lazy(()=> import('./pages/Quote/QuoteForm'))
    },
    {
        path : '/fareestimation',
        element : React.lazy(()=> import('./pages/Quote/FareEstimation'))
    },  
    {
        path : '/stepprocess',
        element : React.lazy(()=> import('./pages/Quote/CustomizableSteps'))
    },
    {
        path : '/orderinfo',
        element : React.lazy(()=> import('./pages/Steps/OrderInfo'))
    },
    // {
    //     path : '/product',
    //     element : null,
    //     children : [
    //         {
    //             path : 'contact',
    //             element : React.lazy(()=> import('./pages/Quote/QuoteForm'))
    //         },
    //     ]
    // },
]

export default routeConfig;