import React from 'react';
import routeConfig from '../routeConfig'
import { useRoutes } from 'react-router-dom';

interface RenderRoutesProps {

}

const RenderRoutes: React.FC<RenderRoutesProps> = () => {
    // Generate routes dynamically using useRoutes
    let isAuth = true

    const element = useRoutes(routeConfig.map((route: any) => ({
        ...route,
        element: (route.element !== null) && (isAuth === true) && (
            <route.element />
        ),
        children: route?.children && (isAuth === true) && route.children.map((child : any) => ({
            ...child,
            element: (
                <child.element /> /* Render child element */
            ),
        })),
    })))

    return element;

};

const Loading = () => {
    return (
        <div>Page Loading...</div>
    )
}

console.log(RenderRoutes, "RenderRoutes")
export default RenderRoutes;